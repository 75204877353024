import React from 'react'
import './style.css' 
import { AiFillFacebook, AiOutlineInstagram } from 'react-icons/ai';
import { FiPhoneCall } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';



function Contact() {
    return (
        <div id='contact' className="contact" >
    
           <div className="contact-div">
           <div className="contact-cont-1">
                <h1  >GET IN TOUCH</h1>
                <div>
                Let's chase your dreams together....
                    
                </div>

                {/* <div>
                 just searchable twitter DMs alone is a massive upgrade.
                 once they add slack, linkedin, etc I'm never leaving.
                </div>

                <div>

                </div> */}
            </div>

            <div className="contact-cont-2">
                
                <div className='contact-1' style={{color:"black"}}>
                <h3 className='call-us'>CALL US</h3>
                <div className='phone_numbers'>
                <a href="tel:+91 9345845046"   className='number'><div className="underline-hover-effect" >+91 9345845046</div>  </a> 
                <a href="tel:+91 9094092274 "  className='number'><div className="underline-hover-effect">+91 9094092274</div> </a> 
                {/* <a href="tel:+91 7010205245 "  className='number'><div className="underline-hover-effect">+91 7010205245</div> </a>    */}
                </div>     
                </div>

                <div className='contact-2' style={{color:"black"}}>
                 <h3 className='location' >Email </h3>
                 {/* <p  className='text-location'> stuvoxel@gmail.com</p> */}
                 <p className='text-location'><a href="mailto:salhanaasmi@gmail.com" className="email underline-hover-effect">salhanaasmi@gmail.com</a></p>
                </div>

                {/* <div>
                <h3 className='Business'>BUSINESS HOURS</h3>
                <p  className='text-location-1'> Mon-Fri : 9:00am -9:00pm</p>
                </div> */}
            </div>
            <div  className="contact-cont-3">
                
            <a href="https://www.instagram.com/tutor_meonline/?utm_medium=copy_link" target="_blank"><AiOutlineInstagram size={30}   color='black'/>  </a> 
                 
            <a href='https://wa.me/message/MWIMIPMCOFFBI1'
            target="_blank"
            rel='noreferrer'
            > < FaWhatsapp  size={30} color='black'/>  </a>   

            <a href="tel: +971508420027 "
            target="_blank"
            rel='noreferrer'
            > <FiPhoneCall  size={28} color='black'/> </a>

            <a href="https://www.facebook.com/Tutorme-108360024777972/"
            target="_blank"
            > <AiFillFacebook size={28} color='black'/> </a>
            

            
             
            </div>
 
            </div>
 
      
           
        </div>
   
    )
}

export default Contact
