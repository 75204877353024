import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
  background:white;
  height:80px;
  /* margin-top: -80px; */
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 8px solid transparent;
  border-image: linear-gradient(45deg, #6d2e8e, #59f2f5);
  border-image-slice: 1;
  

  @media screen and (max-width: 960px){
    transition: 0.8s all ease; 
}
`
export const NavbarContainer = styled.div`
 display: flex;
justify-content:space-between; 
 /* margin: -80%;  */
 height: 80px;
 z-index: 1;
 width: 100%;
 padding: 0 24px;
 max-width: 1100px;
 
 @media screen and (max-width: 1024px) {
    display: flex;
    justify-content:space-evenly; 
     height: 80px;
     z-index: 1;
     width: 100%;

}
@media screen and (max-width: 768px) {
    justify-content:space-between; 
}
`

export const NavLogo = styled(LinkR)`
display: block;
position: relative;
right:23%;
bottom: 60px;
cursor: pointer;
color: black;



@media screen and (max-width:  1024px) {
    display: block;
    position: relative;
    right:20px;
    bottom: 60px;
    cursor: pointer;
    color: black;

}

@media screen and (max-width: 768px) {
    display: block;
    position: relative;
    bottom: 60px;
    cursor: pointer;
    color: black;
}

`

export const MobileIcon = styled.div`
 display: none; 

@media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 50px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: black;
    
}

`

export const NavMenu = styled.ul`
 display: flex;
 align-items: center;
 list-style: none;
 text-align: center;
 margin-right: -180px;
 
 @media screen and (max-width: 1024px) {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right:0px;

    
}

 @media screen and (max-width: 768px) {
     display: none;
     
 }
`


export const NavItem = styled.li`
 height: 80px;
 margin-top: 18px;

 
 
`
export const NavLinks = styled(LinkS)`
 color: black;
 display: flex;
 float: right;
 align-items: center;
 text-decoration: none;
 padding: 0 1rem;
 height: 100%;
 cursor: pointer;

 &:hover {
     transition: all 0.2s ease-in-out; //hover on link not compl
     background: -webkit-linear-gradient(45deg,#6d2e8e, #59f2f5 );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      
     
 }

 &.active {
     /* border-bottom: 2px solid red; */
    color: pink;
     
 }
`



// export const NavBtn = styled.nav`
// display: flex;
// align-items: center;

// @media screen and (max-width: 768px) {
//     display: none;
// }
// `
// export const NavBtnLink = styled(LinkR)`
// border-radius: 50px;
// background: #01bf71;
// white-space: nowrap;
// padding: 10px 22px;
// color: #010606;
// font-size:16px ;
// outline: none;
// border: none;
// cursor: pointer;
// transition: all 0.2s ease-in-out;
// text-decoration: none;

// &:hover {
//     transition: all 0.2s ease-in-out;
//     background: #fff;
//     color: #010606;
// }
// `