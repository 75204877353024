import React from 'react'
import {FaBars} from 'react-icons/fa'
import { Nav, NavbarContainer, NavLogo,MobileIcon , NavMenu,
          NavItem, NavLinks} from './NavbarElements';
import {animateScroll as scroll } from 'react-scroll'
import logo from '../../assets/company.png'
const Navbar = ({toggle}) => {

    const toggleHome = () =>{
       scroll.scrollToTop ()
    }
    
    return (
      <>
        <Nav>
            <NavbarContainer>
                <NavLogo to='/' onClick={toggleHome} > <img src={logo} alt='logoxd' style={{
                    height:200,
                    width:'100%',
                    objectFit:'contain'
                }}/></NavLogo>  
               
                <MobileIcon onClick={toggle}>
                    <FaBars/>
                </MobileIcon >
                <NavMenu >
                    {/* <NavItem>
                        <NavLinks to='Register'> Home</NavLinks>
                    </NavItem> */}
                    <NavItem>
                        <NavLinks to='about'>  About</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='workflow'>Workflow</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='Register'>Testimonials</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='classes'> Our Classes</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='Register'>Register</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='contact'>Contact </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='founder'>Our team </NavLinks>
                    </NavItem>
                    
                    
                </NavMenu>
                {/* <NavBtn>
                    <NavBtnLink to='/register'> Register </NavBtnLink>
                </NavBtn> */}
            </NavbarContainer>
        </Nav>
        </>
    )
}

export default Navbar;
