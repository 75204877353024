import React,{useRef} from 'react'
import { Carousel,Form,Button } from 'react-bootstrap'
import Image1 from '../../assets/testimonial/testimonial1.png'
import Image2 from '../../assets/testimonial/testimonial2.png'
import Image3 from '../../assets/testimonial/testimonial3.png'
import Image4 from '../../assets/testimonial/testimonial4.png'
import Image5 from '../../assets/testimonial/testimonial5.png'
import Image6 from '../../assets/testimonial/testimonial6.png'
import Image7 from '../../assets/testimonial/testimonial7.png'
import Image8 from '../../assets/testimonial/testimonial8.png'
import Image9 from '../../assets/testimonial/testimonial9.png'
import Image10 from '../../assets/testimonial/testimonial10.png'
import './styles.css'

import emailjs from 'emailjs-com'
// import firebase from '../firebaseconfiq'
// import shortId from 'shortid'
function Testimonial() {
  const form =useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_k7tb7fb', 'template_62574ll', form.current, 'UtrxGX9A-psW8XXwB')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
      alert('submited')
       
      
       

  };
  
//   const [name, setName] = useState('')

//   const [email, setEmail] = useState('')

//   const [whatsappNumber, setWhatsappNumber] = useState('')

//   const [cityCountry, setCityCountry] = useState('')

//   const [profession, setProfession] = useState('')

//   const [collegeWorkplace, setCollegeWorkplace] = useState('')

//   const [course, setCourse] = useState('')
  


//   const setTodataBase=async(e)=>{
//     e.preventDefault()
//     if(name===''||email===''||whatsappNumber===null||cityCountry===''||profession===''||collegeWorkplace===''||course==='' )
//     {
//         return alert('Please fill all the fields')
//     }   

//    const validateEmail = (email) => {

// const emailVerify = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//        return emailVerify.test(String(email).toLowerCase());

//    }

//    if(validateEmail(email)===false)
//    {
//        return alert('Email invalid')
    
//    }
//    const id=shortId.generate()
//    await firebase.database().ref(`/data/${id}`).set({name,
//     email,
//     whatsappNumber,
//     cityCountry,
//     profession,
//     collegeWorkplace, 
//     course,  
//     id,
//     date:Date.now()
//   })
//   .then(()=>{
//     setName('')

//     setEmail('')
  
//     setWhatsappNumber('')
  
//     setCityCountry('')
  
//     setProfession('')
  
//     setCollegeWorkplace('')
  
//     setCourse('')

//     alert('submited')
//   })

//   .catch(e=>{
//     alert('error',e)
//   })
//   }
    return (
        <div id='testimonial' className="Testimonialcont">
         <div>
        <Carousel className="carosalCont"   variant="dark">
        <Carousel.Item interval={2000} >
          <img
           className="carsonalimg"
            src={Image1}
            alt="First slide"  
          />
        </Carousel.Item>
        
    <Carousel.Item interval={2000}>
      <img
         className="carsonalimg"
         src={Image2}
        alt="Second slide"
      />
      
    </Carousel.Item>
    <Carousel.Item   interval={2000}      >
      <img
       className="carsonalimg"
       src={Image3}
        alt="Second slide"
      /> 
    </Carousel.Item>
    
    <Carousel.Item interval={2000} >
      <img
       className="carsonalimg"
       src={Image4}
        alt="Second slide"
      />
    </Carousel.Item>
    <Carousel.Item interval={2000}    >
      <img
       className="carsonalimg"
       src={Image5}
        alt="Second slide"
      />
      
    </Carousel.Item>
    <Carousel.Item  interval={2000}       >
      <img
       className="carsonalimg"
       src={Image6}
        alt="Second slide"
      />
    </Carousel.Item>
    <Carousel.Item  interval={2000}       >
      <img
       className="carsonalimg"
       src={Image7}
        alt="Second slide"
      />
    </Carousel.Item>
    <Carousel.Item  interval={2000}       >
      <img
       className="carsonalimg"
       src={Image8}
        alt="Second slide"
      />
    </Carousel.Item>
    <Carousel.Item  interval={2000}       >
      <img
       className="carsonalimg"
       src={Image9}
        alt="Second slide"
      />
    </Carousel.Item>
    <Carousel.Item  interval={2000}       >
      <img
       className="carsonalimg"
       src={Image10}
        alt="Second slide"
      />
    </Carousel.Item>

  </Carousel>
  </div>


  <div className='formCont'id='Register'>

  <Form ref={form} onSubmit={sendEmail}>

  <Form.Group  className="mb-1"   >
    <Form.Label>NAME</Form.Label>
    <Form.Control  type="text" placeholder="Your answer" name="name"/>  
  </Form.Group>

  <Form.Group className="mb-1" >
    <Form.Label>EMAIL ADDRESS</Form.Label>
    <Form.Control   type="email" placeholder="Your answer" name="email" />
  </Form.Group>


  <Form.Group className="mb-1"   >
    <Form.Label>WHATSAPP NUMBER WITH COUNTRY CODE</Form.Label>
    <Form.Control  type="phone" placeholder="Your answer" name="number" />
  </Form.Group>


  <Form.Group  className="mb-1"  >
    <Form.Label>CITY / COUNTRY</Form.Label>
    <Form.Control type="phone" placeholder="Your answer" name="country"/>
  </Form.Group>

  <div className="subBtn">
  <Button variant="primary" type="submit" onSubmit={sendEmail}>
    Submit
  </Button>
</div>

</Form>
  </div>


  </div>
       
    )
}

export default Testimonial


{/* <div className='formCont'id='Register'>

<Form onSubmit={sendEmail}>

<Form.Group  className="mb-1"   >
  <Form.Label>NAME</Form.Label>
  <Form.Control  type="text" placeholder="Your answer" value={name} onChange={e=>setName(e.target.value)} />  
</Form.Group>

<Form.Group className="mb-1" >
  <Form.Label>EMAIL ADDRESS</Form.Label>
  <Form.Control   type="email" placeholder="Your answer" value={email} onChange={e=>setEmail(e.target.value)} />
</Form.Group>


<Form.Group className="mb-1"   >
  <Form.Label>WHATSAPP NUMBER WITH COUNTRY CODE</Form.Label>
  <Form.Control  type="phone" placeholder="Your answer" value={whatsappNumber} onChange={e=>setWhatsappNumber(e.target.value)} />
</Form.Group>


<Form.Group  className="mb-1"  >
  <Form.Label>CITY / COUNTRY</Form.Label>
  <Form.Control type="phone" placeholder="Your answer" value={cityCountry} onChange={e=>setCityCountry(e.target.value)}/>
</Form.Group>



<div className="subBtn">
<Button variant="primary" type="submit" onSubmit={setTodataBase}>
  Submit
</Button>
</div> */}