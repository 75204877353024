import React from 'react'
import image from '../../assets/workflow/vector4.JPG'
import Fade from 'react-reveal/Fade';
import './styles.css'
const Workflow=()=> {
    return (

        <div id='workflow' className="workFlow">
            
            {/* <div className="workflow-header">WORKFLOW</div> */}
            <div className='container'>
            <img src={image} alt='workflow' className="workflow-img" />
            </div>

            
            <div className="container workflow-content-cont">
                <div  className="workflow-content-div">
                    
                <Fade bottom>   <h5 className="workflow-content-header">1. Request</h5> </Fade>
                    <div className="workflow-content-text"> 
                    Tell us what you need help with and our smart matching system will connect you with an online tutor. Sign up for a free trial.
                    </div>
               </div>
                <div className="workflow-content-div">
                <Fade bottom>   <h5 className="workflow-content-header">2. Learn</h5>  </Fade>
                    <div className="workflow-content-text" >
                    Get live 1-on-1 help in our advanced lesson space.Use a virtual whiteboard, audio/video  chat,screen sharing, text editor and much more.
                   </div>
                </div>
                <div className="workflow-content-div-alter">
                <Fade bottom>    <h5 className="workflow-content-header">3. Review</h5>  </Fade>
                    <div className="workflow-content-text">After the lesson is completed, both the tutor and student have the opportunity to rate each other, maintaining the quality of our community.</div>
                </div>   
            </div>
            
            
        </div>
    )
}

export default Workflow
