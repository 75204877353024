import React from 'react'
import logo1 from '../../assets/hero/teach.png'
import logo2 from '../../assets/hero/knowledge.png'
import logo3 from '../../assets/hero/coaching.png'
import image1 from '../../assets/home/home1.jpeg'
import image1After from '../../assets/home/home5.jpeg'
import image2 from '../../assets/home/home2.jpeg'
import image2After from '../../assets/home/home4.jpeg'
import image3 from '../../assets/home/home6.jpeg'
import image3After from '../../assets/home/home3.jpeg'


import './styles.css'

function HeroSection() {
  
    return (
        <div id="about" className="heroSection">
          {/* <button id='reg'>Click mE</button> */}
            <div className="heroSectioncont" >
            <h1 className="heroSection-cont-header">A true 1:1 learning experience with the best online tutors</h1>
            <div className="heroSection-logo-cont">
             <img src={logo1} alt='rino' className="heroSection-logo"/>
             <img src={logo3}  alt='rino' className="heroSection-logo"/>
             <img src={logo2}  alt='rino' className="heroSection-logo"/>
            </div>

            <div className="heroSection-text">
            <div > Our Teachers focus on one student at a time. </div>
            <div>An online classroom designed to accelerate learning.</div>
            <div>Students dropping in for homework help receive immediate access to professional educators, 24/7.</div>
            

            <div className="heroimgcont">
            <div className="heroimgcontdiv">
              <img src={image3} alt='rino' className="HeroSection-img" />
              <div className="middle">
                <img src={image1After} alt='rino'  className="HeroSection-img" />
              </div>
            </div>
              
              <div className="heroimgcontdiv">
              <img src={image2After}  alt='rino' className="HeroSection-img" />
              <div className="middle">
              <img src={image1}  alt='rino' className="HeroSection-img" />
             </div>
             
             </div>
              <div className="heroimgcontdiv">
             <img src={image2} alt='rino' className="HeroSection-img" />
              <div className="middle">
              <img src={image3After} alt='rino' className="HeroSection-img" />
             </div>
          </div> 
          </div>
              
            </div>

      {/* <div className="heroimgcont2">

      <div className="heroimgcontdiv">
        <img src={image4} alt='rino'  className="HeroSection-img" />
        <div className="middle">
          <img src={image4After} alt='rino'  className="HeroSection-img" />
       </div>
        </div>

        <div className="heroimgcontdiv">
        <img src={image5} alt='rino' className="HeroSection-img" />
        <div className="middle">
        <img src={image5After} alt='rino' className="HeroSection-img" />
       </div> 
       </div>

        <div className="heroimgcontdiv">
        <img src={image6} alt='rino' className="HeroSection-img" />
        <div className="middle">
        <img src={image6After} alt='rino' className="HeroSection-img" />
       </div>
       </div>
        
      </div> */}

        </div>
     
        </div>
    )
}

export default HeroSection

