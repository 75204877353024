
import React from 'react'

import what from '../../assets/about/step1.png'
import why from '../../assets/about/step2.png'
import focus from '../../assets/about/step3.png'
import goal from '../../assets/about/step4.png'
import whatafter from '../../assets/about/step1after.png'
import whyafter from '../../assets/about/step2after.png'
import focusafter from '../../assets/about/step3after.png'
import goalafter from '../../assets/about/step4after.png'
import founder from '../../assets/about/founder.jpeg'
import cofounder from '../../assets/about/cofounder.jpeg'
import coordinator from '../../assets/about/coordinator.jpeg'
import Fade from 'react-reveal/Fade';
import './styles.css'


function About() {
    
    return (
        

        <div  className="demo" >
            
          <div>
            
        <div className="container about-cont-1"   >
        
         <div>

            <div className="row">
            
                <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="our-team">
                      <div className="container1">   
                         <div className="pic">
                            <img src={what} alt=""/>
                           <div className="overlay">
                             <img src={whatafter} alt="Avatar" className="image"/>
                           </div>
                         </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-6">

                    <div className="our-team">
                    <div className="container1">
                        <div className="pic">
                            <img src={why} alt=""/>
                            <div className="overlay">
     <img src={whyafter} alt="Avatar" className="image"/>
  </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="our-team">
                      <div className="container1">
                        <div className="pic">
                              <img src={focus} alt="" />
                            <div className="overlay">
                               <img src={focusafter} alt="Avatar" className="image"/>
                             </div>
                        </div>
                      </div>
                     
                    </div>

                </div>
                
                <div className="col-xxl-3 col-lg-6 col-md-6 col-sm-6">
                    <div className="our-team">
                    <div className="container1">
                        <div className="pic">
                            <img src={goal} alt=""/>
                            <div className="overlay">
     <img src={ goalafter} alt="Avatar" className="image"/>
     
  </div>

                        </div>
                      
                        </div>
                        
                        </div>
                        
                    </div>
           
                </div>
             
    <div className="AboutContent">

<Fade left>
<div className="ContextLeftText" style={{textAlign:'right' }} >
<div className="ContextLeftText"  >1 On 1 Live</div>  
<div  className="ContextLeftText">Online Tutoring</div>
<div  className="ContextLeftText">experts guided live </div>

<div  className="ContextLeftText">learning sessions</div>

</div>
</Fade>

<div>

    <p className="ContexRightText">
    <b className="ContexRightTextbr">TUTOR ME</b> Looking for best home tutors in world?
     We have 100+ qualified home tutors/tuition teachers to work with students of all ages from Pre-Nursery to Post Graduate, covering
        
     <div className="ContexRightTextdiv">
        all academic and competitive subjects and general interest/hobby classes. 
    </div>
    <div className="ContexRightTextdiv">
    Search for the best tutor across globally and get one to one free trial session.</div>
    </p>
  
</div>

</div>

                </div>
              
            </div>
        
        </div>   

        <div className="container  about-cont-2" id="founder" >
            <div className="row">
            
                <div className="col-xxl-4 col-lg-12 col-md-12 col-sm-6">
                    <div className="our-team">
                        <div className="pic">
                            <img src={founder} alt=""/>
                        </div>
                       
                        
                        <div className="team-content">
                            
                            <h3 className="title">SALHANA ASMI AZEEM <br></br>(Founder/CEO of SALHANA's TUTORME ACADEMY)</h3>
                        </div>
                        
                        <p  style={{textAlign:'justify'}}>
                       
                        </p>
                    </div>
                </div>
                
                
                <div className="col-xxl-4 col-lg-12 col-md-12 col-sm-6">
                    <div className="our-team">
                        <div className="pic">
                            <img src={cofounder} alt=""/>
                        </div>
                        
                        <div className="team-content">
                             <h3 className="title">AZEEM SHEIK <br></br>(CO-Founder of SALHANA's TUTORME ACADEMY)</h3>
                            
                        </div>
                        <p  style={{textAlign:'justify'}}>
                       
                        </p>
                    </div>
                </div>
                
                <div className="col-xxl-4 col-lg-12 col-md-12 col-sm-6">
                    <div className="our-team">
                        <div className="pic">
                            <img src={coordinator} alt=""/>
                        </div>
                       
                        <div className="team-content">
                            <h3 className="title">RESHMA PARVEEN <br></br>(COORDINATOR  CUM EDUCATOR OF SALHANA's TUTORME ACADEMY)</h3>
                          
                        </div>
                        <p  style={{textAlign:'justify'}} >
                    
                        </p>
                    </div>
                </div>
                
            </div>
        </div>   
    </div>
    

    )
}

export default About

