import React, { useState } from 'react'
// import InfoSection from '../components/InfoSection'
// import { homeObjFour, homeObjOne, homeObjThree, homeObjTwo } from '../components/InfoSection/Data'
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import About from '../components/About/index'
import Testimonial from '../components/Testimonial';
import HeroSection from '../components/HeroSection';
import WorkFlow from '../components/WorkFlow';
import Exploration from '../components/Exploration/Index';
import Contact from '../components/Contact';
// import Homie from '../components/Homie/homie';





const Home = () => {

  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }


  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      {/* <Header/> */}
      {/* <CarouselContainer /> */}
      <Navbar toggle={toggle} />
      {/* <Homie /> */}
      <HeroSection/>
      <WorkFlow/>
      <About/>
      <Exploration/>   
      <Testimonial/>
      <Contact/>

    </>
  )
}

export default Home
