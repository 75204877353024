import React from 'react'
import { SidebarContainer, Icon,CloseIcon, SidebarWrapper, 
SidebarMenu,SidebarLink} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    {/* <SidebarLink to='home' onClick={toggle}>
                       Home
                    </SidebarLink> */}
                    <SidebarLink to='Register' onClick={toggle}>
                       FREE DEMO CLASS
                    </SidebarLink>
                    <SidebarLink to='about' onClick={toggle}>
                          About 
                    </SidebarLink>
                    <SidebarLink to='workflow' onClick={toggle}>
                       Workflow
                    </SidebarLink>
                    <SidebarLink to='testimonial' onClick={toggle}>
                      Testimonials
                    </SidebarLink>
                    <SidebarLink to='classes' onClick={toggle}>
                        Our Classes
                    </SidebarLink>
                    
                    <SidebarLink to='contact' onClick={toggle}>
                       Contact
                    </SidebarLink>
                    <SidebarLink to='founder' onClick={toggle}>
                       Our team
                    </SidebarLink>
                   
                </SidebarMenu>
                {/* <SideBtnWrap>
                    <SidebarRoute to='/register'> Register</SidebarRoute>
                </SideBtnWrap> */}
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
